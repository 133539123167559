import React from "react";
import Layout from "../components/Layout";
import MobileMenu from "../components/MobileMenu";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import BlogHome from "../components/BlogHome";
import CTAThree from "../components/CTAThree";
import Contact from "../components/Contact";
import Services from "../components/Services";
import CTAOne from "../components/CTAOne";
import FunFact from "../components/FunFact";
import CTATwo from "../components/CTATwo";
import Team from "../components/Team";
import Pricing from "../components/Pricing";
import VideoOne from "../components/VideoOne";
import AppScreen from "../components/AppScreen";
import FAQ from "../components/FAQ";
import Clients from "../components/Clients";
import Testimonials from "../components/Testimonials";
import OverView from "../components/OverView";
import GSTFiling from "../components/GSTFiling";
import RECInvoice from "../components/RECInvoice";
import Storage from "../components/Storage";

const HomePage = () => (
  <div>
    <Layout pageTitle="Accountsdesk | Home Page">
      <Header
        btnClass="main-nav__btn"
        extraClassName="site-header-one__fixed-top"
      />
      <MobileMenu />
      <Banner />
      <Services />
      <CTAOne />
      <FunFact />
      <CTATwo />
      <GSTFiling />
      <RECInvoice />
      <Storage />
      <Pricing />
      <Testimonials />
      {/* <Clients /> */}
      {/* <Team /> */}
      {/* <VideoOne /> */}
      {/* <AppScreen /> */}
      <OverView />
      <FAQ />
      {/* <BlogHome /> */}
      {/* <Contact /> */}
      <CTAThree />
      <Footer />
    </Layout>
  </div>
);

export default HomePage;
